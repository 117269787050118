import request from '@/utils/request'

export function setPassword(newPassword,confirmPassword,email){
    const data = {
        newPassword,
        confirmPassword,
        email,
      }
    return request({
        url:"/sys/user/resetPassword",
        method:'post',
        data
    })
}