<template>
  <div class="login-container">
    <svg-icon class="logo" icon-class="logo" />
    <div class="des">
      <svg-icon icon-class="kouhao1" style="width:100%;height:auto" />
    </div>
    <div class="login-form-layout">
      <el-form ref="resetForm" :model="resetForm" :rules="resetRules" class="login-form" auto-complete="on" label-position="left">
        <div class="title-container">
          <h3 class="title">重置密码</h3>
          <!-- 下划线 -->
          <div class="line"></div>
        </div>

        <div class="landing-content">
          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input v-model="resetForm.password" placeholder="设置密码" name="username" :type="passwordType" auto-complete="on" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>

          <el-form-item prop="confirmPassword">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input style="width: 50%" v-model="resetForm.confirmPassword" :type="passwordType" placeholder="确认密码" name="code" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
          <el-button :loading="loading" type="primary" style="width: 320px" @click.native.prevent="setPassword">提交</el-button>
        </div>
        <div class="forget" @click="goLogin">直接登录</div>
      </el-form>
    </div>
    <div style="display: flex;justify-content: space-around;color: #ffffff;font-size: 14px;width: 100%;bottom: 20px;position: fixed;">
      <p>商务联系 丨 技术支持：colorx_robot@aliyun.com</p>
      <p>版权所有colorX丨Copyright-2020 All Rights Reserved.</p>
    </div>
  </div>
</template>
<script>
import { isvalidUsername, guid } from "@/utils/validate";
import { Message } from "element-ui";
import Cookies from "js-cookie";
import { setPassword } from "@/api/reset";
export default {
  name: "reset",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error("密码不能少于4位数"));
      } else {
        callback();
      }
    };
    //确认密码是否一致
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      resetForm: {
        password: "",
        confirmPassword: "",
      },
      resetRules: {
        password: [
          { required: true, trigger: "blur", validator: validatePassword }, //validator: validateUsername
        ],
        confirmPassword: [
          { required: true, trigger: "blur", validator: validatePass2 },
        ],
      },
      loading: false,
      showDialog: false,
      passwordType: "password",
      imgUrl: "",
    };
  },
  created() {
    // this.loginForm.uuid = guid();
    // this.imgUrl =
    //   process.env.VUE_APP_BASE_API + `captcha.jpg?uuid=${this.loginForm.uuid}`;
  },
  methods: {
    // 跳转页面
    goLogin() {
      Cookies.remove("Tiger-Admin-Token");
      this.$store.commit("SET_TOKEN", "");
      this.$router.push("/");
    },
    //验证用户设置密码
    setPassword() {
      if (this.resetForm.password && this.resetForm.confirmPassword != "") {
        const newPassword = this.resetForm.password;
        const confirmPassword = this.resetForm.confirmPassword;
        const email = localStorage.getItem("email");
        // sessionStorage.removeItem('email')
        // sessionStorage.removeItem('token')
        // const token=localStorage.getItem("token")
        setPassword(newPassword, confirmPassword, email).then(({ data }) => {
          console.log(data);
          if (data.code === 0) {
            Cookies.remove("Tiger-Admin-Token");
            this.$store.commit("SET_TOKEN", "");
            this.$router.push("/login");
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "密码不能为空",
          type: "error",
        });
      }
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #eee;
$cursor: #fff;
/* reset element-ui css */
.login-container {
  background: rgba(13, 78, 230, 0.06) url("../../assets/Background.png") center
    center no-repeat;
  .logo {
    width: 187px;
    height: 94px;
    position: fixed;
    top: 5%;
    left: 3.9%;
  }
  .des {
    position: fixed;
    top: 50%;
    margin-top: -140px;
    left: 10%;
    max-width: 731px;
  }
  .login-form-layout {
    position: absolute;
    top: 50%;
    left: 58%;
    margin-top: -270px;
    background: #fefdfd;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.18);
    opacity: 1;
    border-radius: 10px;
    .forget {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
      opacity: 1;
      position: absolute;
      right: 50px;
      bottom: 50px;
      cursor: pointer;
    }
  }
  .landing-content {
    width: 320px;
    height: 249px;
    margin: 0 auto;
    position: relative;
    padding-top: 10px;
    .code {
      width: 96px;
      height: 32px;
      border: 1px solid #407cf3;
      opacity: 1;
      border-radius: 4px;
      position: absolute;
      top: 100px;
      left: 228px;
      background-color: #fefdfd;
      color: #407cf3;
      font-size: 14px;
    }
  }
  .landing-content ::v-deep {
    .el-input__inner {
      border: 0;
    }
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 0px;
      height: 47px;
      // caret-color: $cursor;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }
  .el-form-item {
    width: 320px;
    border-bottom: 1px solid #cfcedb;
  }
  .el-button--primary {
    width: 320px;
    height: 50px;
    background: #42d6d7;
    opacity: 1;
    border-radius: 5px;
    margin-top: 36px;
    border-color: white;
  }
  .el-input:-webkit-autofill:focus {
    // -webkit-box-shadow: none!important;
    // -webkit-text-fill-color: white;
  }
}
.login-container .el-input input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f0f3fa inset !important;
  -webkit-text-fill-color: #000 !important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
// $bg:#2d3a4b;
$bg: transparent;
$dark_gray: #2e414e;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  .vcode {
    position: absolute;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .login-form {
    position: relative;
    width: 420px;
    height: 430px;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 1px;
    color: $dark_gray;
    vertical-align: middle;
    width: 40px;
    display: inline-block;
    font-size: 21px;
  }
  .title-container {
    position: relative;
    .title {
      width: 120px;
      height: 28px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 28px;
      color: #000000;
      opacity: 1;
      margin-left: 54px;
    }
    .line {
      position: absolute;
      width: 80px;
      height: 3px;
      background: #42d6d7;
      opacity: 1;
      // margin: 0 auto;
      top: 37px;
      left: 54px;
    }
    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
@media screen and (min-width: 1024px) {
  .des {
    width: 450px;
  }
} /*>=1024的设备屏幕*/

@media screen and (min-width: 1100px) {
  .des {
    width: 450px;
  }
} /*>=1100的设备屏幕*/
@media (min-width: 1280px) {
  .des {
    width: 500px;
  }
} /*>=1280的设备屏幕*/

@media screen and (min-width: 1366px) {
  .des {
    width: 531px;
  }
} /*>=1366的设备屏幕*/

@media screen and (min-width: 1440px) {
  .des {
    width: 631px;
  }
} /*>=1440的设备屏幕*/

@media screen and (min-width: 1680px) {
  .des {
    width: 731px;
  }
} /*>=1680的设备屏幕*/
@media screen and (min-width: 1920px) {
  .des {
    width: 731px;
  }
} /*>=1920的设备屏幕*/
</style>
